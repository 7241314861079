import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { KickfloFrontendComponent } from './kickflo-frontend.component';
import { KickfloFrontendRoutingModule } from './kickflo-frontend-routing.module';
import { CoreFeatureFlagModule } from '@serious-stack/core/feature-flag/angular';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  CoreAuthSettingsRepository,
  CoreAuthSettingsStoreProvider,
} from '@serious-stack/core/auth/angular';

import { KickfloFrontendAuthModule } from './modules/auth/kickflo-frontend-auth.module';

//Components
import { ReusableComponentsModule } from './components/reusable-components.module';
import { CommonModule } from '@angular/common';
import { ApiModule, BASE_PATH } from './_generated';
import { BankingInformationService } from './_generated/api/bankingInformation.service';
import { UserService } from './services/user.service';
import { UsersService } from './_generated/api/users.service';
import { OfferService } from './_generated/api/offer.service';
import { OffersService } from './_generated/api/offers.service';
import { DealService } from './_generated/api/deal.service';
import { DealsService } from './_generated/api/deals.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TaskService } from './_generated/api/task.service';
import { TasksService } from './_generated/api/tasks.service';
import { AssetService } from './_generated/api/asset.service';
import { TermsService } from './_generated/api/terms.service';
import { ChatService } from './_generated/api/chat.service';
import { MediaLibraryModule } from '@serious-stack/core/media-library/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChatModule } from './modules/chat/chat.module';
import { MediaService } from './_generated/api/media.service';
import { CompanyInterceptor } from './interceptors';
import { PlatformService } from './_generated/api/platform.service';
import { CollaborationAgreementInformationService } from './_generated/api/collaborationAgreementInformation.service';
import { CompanyService } from './_generated/api/company.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.error(
      `Missing translation 😜 : ${params.key} for language ${params.translateService.store.langs}`
    );
    return `!⚠️ ${params.key} ⚠️!`;
  }
}

const API_SERVICES = [
  OfferService,
  OffersService,
  UserService,
  UsersService,
  DealService,
  DealsService,
  TaskService,
  TasksService,
  AssetService,
  MediaService,
  TermsService,
  BankingInformationService,
  PlatformService,
  CompanyService,
  CollaborationAgreementInformationService,
  ChatService,
];

@NgModule({
  declarations: [KickfloFrontendComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
    }),
    IonicStorageModule.forRoot(),
    KickfloFrontendRoutingModule,
    KickfloFrontendAuthModule,
    CommonModule,
    CoreFeatureFlagModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
      extend: true,
    }),
    MediaLibraryModule.forRoot({
      cloud_name: `${process.env.NG_APP_CLOUDINARY_CLOUD_NAME}`,
      api_key: `${process.env.NG_APP_CLOUDINARY_API_KEY}`,
    }),
    ChatModule,
    ReusableComponentsModule,
    ApiModule,
    BrowserAnimationsModule,
  ],
  providers: [
    Storage,
    {
      provide: BASE_PATH,
      useValue: `${process.env.NG_APP_API_ENDPOINT}`,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          translateService: TranslateService,
          coreAuthSettingsRepository: CoreAuthSettingsRepository
        ) =>
        () => {
          const currentLang =
            coreAuthSettingsRepository.storeValue.currentLanguage ||
            CoreAuthSettingsStoreProvider.AvailableLanguages.EN;

          translateService.addLangs([CoreAuthSettingsStoreProvider.AvailableLanguages.EN]);
          translateService.setDefaultLang(CoreAuthSettingsStoreProvider.AvailableLanguages.EN);

          return translateService.use(currentLang);
        },
      deps: [TranslateService, CoreAuthSettingsRepository],
      multi: true,
    },
    API_SERVICES,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CompanyInterceptor,
      multi: true,
    },
  ],
  bootstrap: [KickfloFrontendComponent],
})
export class KickfloFrontendModule {}

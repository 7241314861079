import { Injectable, Optional } from '@angular/core';
import { CloudinaryImage, CloudinaryVideo } from '@cloudinary/url-gen';
import { format, quality } from '@cloudinary/url-gen/actions/delivery';
import { scale, thumbnail } from '@cloudinary/url-gen/actions/resize';
import { trim } from '@cloudinary/url-gen/actions/videoEdit';
import { FocusOn } from '@cloudinary/url-gen/qualifiers/focusOn';
import { videoWebm } from '@cloudinary/url-gen/qualifiers/format';
import { focusOn } from '@cloudinary/url-gen/qualifiers/gravity';
import { MediaFormatType } from '../_constants/media-format-type';
import { MediaType } from '../_constants/media-type';

export class MediaLibraryServiceConfig {
  cloud_name = '';
  api_key = '';
}

@Injectable({
  providedIn: 'root',
})
export class MediaLibraryService {
  videoFormatTypes = MediaFormatType.VideoFormatType;
  imageFormatTypes = MediaFormatType.ImageFormatType;

  private _cloud_name = '';
  private _api_key = '';

  get cloudName() {
    return this._cloud_name;
  }

  get apiKey() {
    return this._api_key;
  }

  constructor(@Optional() config?: MediaLibraryServiceConfig) {
    if (config) {
      this._cloud_name = config.cloud_name;
      this._api_key = config.api_key;
    }
  }

  /**
   * upload a file to cloudinary using the received signature
   * @param file file to uopload
   * @param signData signature to authenticate the request
   * @returns file uploaded metadata
   */
  async upload(file: any, signData: { signature: string; folder: string; timestamp: string }) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('api_key', this._api_key);
    formData.append('signature', signData.signature);
    formData.append('folder', signData.folder);
    formData.append('timestamp', signData.timestamp);

    return fetch('https://api.cloudinary.com/v1_1/' + this._cloud_name + '/auto/upload', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        return JSON.parse(data);
      });
  }

  /**
   * return the type of the uploaded asset
   * @param type
   * @returns
   */
  getTypes(type: string): string {
    if (this.imageFormatTypes.includes(type)) {
      return MediaType.IMAGE;
    }

    if (this.videoFormatTypes.includes(type)) {
      return MediaType.VIDEO;
    }

    return MediaType.UNDEFINED;
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of an image transformed in thumbnail
   */
  async thumbnailPicture(publicId: string): Promise<string> {
    const image = new CloudinaryImage(publicId, {
      cloudName: this._cloud_name,
    }).resize(thumbnail().width(150).height(150).gravity(focusOn(FocusOn.face())));

    return image.toURL();
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of an image resized according to the platform width
   */
  async optimizedPicture(publicId: string) {
    const image = new CloudinaryImage(publicId, {
      cloudName: this._cloud_name,
    }).resize(scale().width(window.innerWidth));

    return image.toURL();
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of the original image
   */
  async originalPicture(publicId: string) {
    const image = new CloudinaryImage(publicId, {
      cloudName: this._cloud_name,
    });

    return image.toURL();
  }

  /**
   *
   * @param publicId public ID on cloudinary
   * @returns the url of the original video
   */
  async originalVideo(publicId: string) {
    const video = new CloudinaryVideo(publicId, {
      cloudName: this._cloud_name,
    });

    return video.toURL();
  }

  /**
   *
   * @param publicId public ID on cloudinary
   * @param duration number of seconds
   * @returns the url of a sampled video
   */
  async sampleDurationVideo(publicId: string, duration: number) {
    const video = new CloudinaryVideo(publicId, { cloudName: this._cloud_name })
      .videoEdit(trim().duration(duration))
      .delivery(format(videoWebm()));

    return video.toURL();
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of the webm video
   */
  async optimizedVideo(publicId: string) {
    const video = new CloudinaryVideo(publicId, {
      cloudName: this._cloud_name,
    }).delivery(format(videoWebm()));

    return video.toURL();
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of the low quality video
   */
  async lowQualityVideo(publicId: string) {
    const video = new CloudinaryVideo(publicId, { cloudName: this._cloud_name })
      .delivery(quality(50))
      .delivery(format(videoWebm()));

    return video.toURL();
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of the video thumbnail
   */
  async thumbnailVideo(publicId: string) {
    const thumbnail = new CloudinaryImage(publicId + '.jpg', {
      cloudName: this._cloud_name,
    }).setAssetType('video');

    return thumbnail.toURL();
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of the original pdf
   */
  async originalPdf(publicId: string) {
    const pdf = new CloudinaryImage(publicId, {
      cloudName: this._cloud_name,
    });

    return pdf.toURL();
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of the pdf thumbnail
   */
  async thumbnailPdf(publicId: string) {
    const thumbnail = new CloudinaryImage(publicId + '.jpg', {
      cloudName: this._cloud_name,
    });

    return thumbnail.toURL();
  }

  /**
   * Get and return the Cloudinary URL of a video delivered in mp4.
   * @param publicId The public ID of the video.
   * @returns The Cloudinary URL of the video delivered in mp4.
   */
  videoMp4(publicId: string) {
    const video = new CloudinaryVideo(`${publicId}.mp4`, {
      cloudName: this._cloud_name,
    });
    return video.toURL();
  }
}
